@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
    overflow-x: hidden;
    width: 100vw;
}

.player iframe {
    @apply w-full h-full rounded-xl;
}